.font-face-filsonSoftHeavy {
    font-family: "FilsonSoftW03-Heavy";
  }
  .font-face-filsonSoftMedium {
    font-family: "FilsonSoftW03-Medium";
  }
  .font-face-filsonSoftPro {
    font-family: "Filson Pro Light";
  }
  .font-face-filsonSoftBold{
    font-family:"FilsonSoftW03-Bold"
  }