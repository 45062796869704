@font-face {
    font-family: "FilsonSoftW03-Heavy";
    src: local("FilsonSoftW03-Heavy"),
     url("./fonts/FilsonSoft/Filson Soft W03 Heavy.ttf") format("truetype");
    font-weight: bold;
   }
   @font-face {
    font-family: "FilsonSoftW03-Medium";
    src: local("FilsonSoftW03-Medium"),
     url("./fonts/FilsonSoft/Filson Soft W03 Medium.ttf") format("truetype");
    font-weight: bold;
   }
  @font-face {
    font-family: "FilsonSoftW03-Bold";
    src:local("FilsonSoftW03-Bold"),
  url("./fonts/FilsonSoft/Filson\ Soft\ W03\ Bold.ttf")format("truetype");
  font-weight: bold;
  }